@import '../../../../../../shopfront/sass/constants';
@import '../../../../../../shopfront/sass/mixins/blockButtonMixins';
@import '../../../../../../shopfront/sass/mixins/imageMixins';


.UserOrderHistory {
    margin-top: 48px;
    @media (max-width: $screen-tablet-1) {
        margin-top: 24px;
    }
}

.UserOrderHistory__loadMoreButton {
    margin: 8px;
    appearance: none;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
    line-height: 24px;
    padding: 8px 4px;
    color: rgba(0, 0, 0, .5);
    background-color: var(--sf-block-button-bg-color);

    &:hover, &:active {
        color: rgba(0, 0, 0, .5);
        background-color: rgba(0, 0, 0, .1);
    }

    &:active {
        background-color: rgba(0, 0, 0, .15);
    }

    border-radius: var(--sf-border-radius);
    @media (max-width: $screen-tablet-1) {
        margin-left: 0;
    }
}

.UserOrderHistory__noOrdersLabel {
    display: block;
    padding: 0 24px 16px 12px;
    @media (max-width: $screen-tablet-1) {
        padding: 0 8px 24px;
    }
}

.UserOrderHistory__loadMoreButtonIcon {
    margin-right: 8px;
    color: rgba(0, 0, 0, .5);

    .svgicon {
        height: 24px;
        width: 24px;
    }
}

.userOrder {
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 8px 24px 8px 12px;
    align-items: center;
    flex-wrap: wrap;
    min-height: 56px;
    position: relative;
    background-color: var(--sf-block-button-bg-color);
    border: 1px solid var(--sf-block-button-border-color);
    border-radius: var(--sf-border-radius);
    margin-bottom: 8px;
    user-select: none;
    @media (max-width: $screen-tablet-1) {
        position: relative;
        padding: 8px;
    }
}

.userOrder__bbb {
    @include blockButtonBorderElement();
}

.userOrder__codeAndDate {
    flex-basis: 140px;
    white-space: nowrap;

    strong {
        font-size: $font-size-120rem;
        font-weight: var(--sf-font-weight-bold);
    }
}

.userOrder__part {
    flex-basis: 18%;

    @media (max-width: $screen-tablet-1) {
        line-height: 1.2;
        flex-basis: 22%;
        text-align: left;
    }

    &--openIcon {
        flex-basis: 44px;
    }

    &--alignRight {
        text-align: right;
    }

    &--mobileHidden {
        @media (max-width: $screen-tablet-1) {
            display: none;
        }
    }
}

.userOrder__part__secondLine {
    padding-top: 4px;

    &--mobileOnly {
        display: none;
        @media (max-width: $screen-tablet-1) {
            display: block;
        }
    }
}

.userOrderOpenIcon {
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    opacity: .35;

    @media (max-width: $screen-tablet-1) {
        width: 12px;
        height: 12px;
        line-height: 12px;
    }

    transition: transform .1s linear;

    &--opened {
        transform: rotate(45deg);
    }
}

.userOrderOpenIcon__line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: black;
    left: 0;
    top: 50%;

    &--vertical {
        transform: rotate(90deg);
    }
}

.userOrderDetail {
    border: 1px solid var(--sf-line-color-light);
    border-radius: var(--sf-border-radius);
    padding: 12px;
    margin-bottom: 48px;
}

.userOrderDetail__heading {
    display: block;
    margin: 0 0 .5lh;
}

.userOrderDetail__orderItem {
    width: 100%;
    padding: 8px 0;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--sf-line-color-light);
}

.userOrderDetail__orderItem__count {
    height: 56px;
    display: flex;
    align-items: center;
}

.userOrderDetail__orderItem__image {
    width: 56px;
    height: 56px;
    position: relative;
    overflow: hidden;
    border-radius: var(--sf-border-radius);
    display: flex;
    @include imageOverlay();

    picture {
        display: contents;
    }

    img {
        width: 56px;
        height: 56px;
        object-fit: contain;
        background-color: white;
    }
}

.userOrderDetail__orderItem__main {
    display: flex;
    flex-direction: column;
    min-height: 56px;
    justify-content: center;
    align-items: start;
}

.userOrderDetail__orderItem__prices {
    margin-left: auto;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    min-height: 56px;
    justify-content: center;
    align-items: end;

    > *:first-child > *:first-child {
        font-weight: var(--sf-font-weight-bold);
    }

    > *:nth-child(2) {
        opacity: .5;
    }
}

.userOrderDetail__orderItem__addToCart {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.userOrderDetail__orderItem__productName {
    font-weight: var(--sf-font-weight-bold) !important;
}

.userOrderDetail__orderItem__vehicle {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 4px 12px;
}

.userOrderDetail__orderItem__vehicle__licensePlate,
.userOrderDetail__orderItem__vehicle__vin {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 2px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    box-sizing: border-box;
    letter-spacing: .1em;
    padding: 0 4px;
    font-weight: var(--sf-font-weight-bold);
}

.userOrderDetail__orderItem__vehicle__licensePlate {
    border-left-width: 12px;
    min-width: 88px;
    color: inherit;
    font-weight: var(--sf-font-weight-bold);

    &:hover {
        border-color: rgba(0, 0, 0, .35);
        color: black;
    }
}

.userOrderDetailTotalPrice {
    margin-top: 16px;
    text-align: right;
}

.userOrderDetailTotalPrice__priceAmount {
    display: block;
    font-weight: var(--sf-font-weight-bold);
    font-size: 1.5rem;
}

.userOrderDetailTotalPrice__priceAmountWithoutVat {
    font-weight: var(--sf-font-weight-normal);
}

.userOrderDetail__comment {

}

.userOrderDetail__commentText {
    padding: 0 0 8px;
}

.userOrderDetail__contacts {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    @media (max-width: $screen-tablet-1) {
        margin-top: 0;
        flex-wrap: wrap;
        flex-basis: 100%;
    }
}

.userOrderBillingDeliveryContact {
    flex: 0 0 48%;
    @media (max-width: $screen-tablet-1) {
        flex-basis: 100%;
    }
}

.userOrderBillingDeliveryContact__heading {
    display: block;
    margin: 0 0 .5lh;
}

.paymentStatus {
    font-weight: bold !important;

    &--paid {
        color: limegreen !important;
        cursor: default !important;

        &:hover {
            color: limegreen !important;
        }
    }

    &--pay {
        color: orange;
    }
}